@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  font-family: "Roboto";
}

.header-logo {
  display: flex;
  justify-content: center;
  padding: 1rem 0;
  margin-bottom: 1rem;
}

body {
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

html {
  height: -webkit-fill-available;
}

.content {
  display: grid;
  grid-template-rows: auto auto -webkit-min-content;
  grid-template-rows: auto auto min-content;
}

.google-maps {
  height: 30vh;
}

@keyframes button-spinner {
  from {
    transform: rotate(0turn);
  }
  to {
    transform: rotate(1turn);
  }
}

.button::after {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  margin: auto;
  border: 2px solid transparent;
  border-top-color: #ffffff;
  border-radius: 75%;
  animation: button-spinner 1s ease-in-out infinite;
}

@keyframes animation {
  from {
    left: -100%;
  }

  to {
    left: 100%;
  }
}

